/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

   // Use this variable to set up the common and page specific functions. If you
   // rename this variable, you will also need to rename the namespace below.
   var Sage = {
      // All pages
      'common': {
         init: function () {
            // JavaScript to be fired on all pages

         },
         finalize: function () {
            if ($('#landing-page').length == 0) {
               $('header .navbar').removeClass('navbar-transparent');
            }
            $('#newsletter-success').hide();

            // JavaScript to be fired on all pages, after page specific JS is fired
            $('#menu-toggle').on('click', function (event) {
               event.preventDefault();
               $(this).toggleClass('is-active');
               $('body').toggleClass('menu-open ');
            });

            $('#newsletter-form').submit(function () {
               $('#newsletter-submit').prop('disabled', true);
               $.ajax({
                  type: 'POST',
                  url: $(this)[0].url.value,
                  data: {
                     nn: $(this)[0].nn.value,
                     ne: $(this)[0].ne.value
                  }
               }).done(function () {
                  $('#newsletter-success').fadeIn();
                  $('#newsletter-submit').prop('disabled', false);
                  setTimeout(function () {
                     $('#newsletter-success').fadeOut();
                  }, 10000);
               });
               return false;
            });

         }
      },
      // Home page
      'home': {
         init: function () {
            // JavaScript to be fired on the home page
         },
         finalize: function () {
            // JavaScript to be fired on the home page, after the init JS
            var $sliderProduct = $('#slider-products-sale');
            $sliderProduct.carousel({
               interval: false
            });
            $sliderProduct.hammer().on('swipeleft', function () {
               $(this).carousel('next');
            });

            $sliderProduct.hammer().on('swiperight', function () {
               $(this).carousel('prev');
            });

            $('#landing-page').height($(window).innerHeight());

            $(window).resize(function () {
               $('#landing-page').height($(window).innerHeight());
            });


            $(window).scroll(function () {
               var $navbar = $('header .navbar');
               var offsetTop = $('section.about').offset().top - 100;
               var $scrollTop = $(this).scrollTop();
               if (offsetTop - $scrollTop > 0 && !$navbar.hasClass('navbar-transparent')) {
                  $navbar.addClass('navbar-transparent');
               }
               if (offsetTop - $scrollTop <= 0 && $navbar.hasClass('navbar-transparent')) {
                  $navbar.removeClass('navbar-transparent');
               }

               $('.landing-page-content').css({
                  'opacity': 1 - $scrollTop / offsetTop
               });

            });
         }
      },
      // About us page, note the change from about-us to about_us.
      'about_us': {
         init: function () {
            // JavaScript to be fired on the about us page
         }
      },

      'woocommerce_page': {
         init: function () {
            $('.quantity .btn-default').on('click', function (event) {
               event.preventDefault();
               var $button = $(this);
               var $input = $button.parent().find('input.qty');
               if ($button.hasClass('increase')) {
                  $input.val(parseFloat($input.val()) + 1);
               } else if ($button.hasClass('decrease') && $input.val() > 1) {
                  $input.val(parseFloat($input.val()) - 1);
               }
               if ($('input[name=update_cart]') !== undefined) {
                  $('input[name=update_cart]').prop('disabled', false);
               }
            });
         },
         finalize: function () {
            // var map = new google.maps.Map(document.getElementById('map'), {
            //   center: {lat: -34.397, lng: 150.644},
            //   scrollwheel: false,
            //   zoom: 8
            // });
            $('#slider-products-related .item').each(function () {
               var next = $(this).next();
               if (!next.length) {
                  next = $(this).siblings(':first');
               }
               next.children(':first-child').clone().appendTo($(this));

               if (next.next().length > 0) {

                  next.next().children(':first-child').clone().appendTo($(this)).addClass('rightest');

               }
               else {
                  $(this).siblings(':first').children(':first-child').clone().appendTo($(this));
               }
            });
         }
      }
   };

   // The routing fires all common scripts, followed by the page specific scripts.
   // Add additional events for more control over timing e.g. a finalize event
   var UTIL = {
      fire: function (func, funcname, args) {
         var fire;
         var namespace = Sage;
         funcname = (funcname === undefined) ? 'init' : funcname;
         fire = func !== '';
         fire = fire && namespace[func];
         fire = fire && typeof namespace[func][funcname] === 'function';

         if (fire) {
            namespace[func][funcname](args);
         }
      },
      loadEvents: function () {
         // Fire common init JS
         UTIL.fire('common');

         // Fire page-specific init JS, and then finalize JS
         $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
            UTIL.fire(classnm);
            UTIL.fire(classnm, 'finalize');
         });

         // Fire common finalize JS
         UTIL.fire('common', 'finalize');
      }
   };

   // Load Events
   $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
